import styles from 'bundle-text:./index.scss';

class ZwappyStory {

  constructor() {
    this.el = document.querySelector('#zwappy-story')
    this.url = this.el.dataset.zwUrl
    this.mediaAsset = this.el.dataset.zwImage
    this.captionBg = this.el.dataset.zwCaptionBg
    this.captionText = this.el.dataset.zwCaptionText
    this.fileType = this.mediaAsset.split(/[#?]/)[0].split('.').pop().trim()

    this.init()
  }

  init () {
    const popover = document.createElement('div')
    popover.id = 'zwappy-popover'
    popover.innerHTML = this.getPopoverHtml()
    document.body.appendChild(popover)

    this.setStyles()

    document.querySelector('.zw-popover-close-button').addEventListener('click', this.removePopover)
    document.querySelector('.zw-image-container').addEventListener('click', this.showOverlay.bind(this))
  }

  setStyles () {
    const style = document.createElement('style');
    style.innerHTML = styles
    document.head.appendChild(style);
  }

  removePopover () {
    document.querySelector('#zwappy-popover').remove()
  }

  hidePopover () {
    const popoverEl = document.querySelector('#zwappy-popover .zw-popover')
    popoverEl.style.display = 'none'
    popoverEl.classList.remove('init')
  }

  showPopover () {
    document.querySelector('#zwappy-popover .zw-popover').style.display = 'block'
  }

  getPopoverHtml () {
    return `
      <div class="zw-popover init">
        <div class="zw-popover-container js-thumb">
          <button class="zw-popover-close-button">
            <span class="zw-popover-close-icon">
              <svg height="200" width="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"><path d="M40.342 34.912c-2.15.759-4.747 3.469-5.524 5.765-.805 2.381-.744 5.137.161 7.2.585 1.335 5.184 6.098 25.948 26.873L86.165 100l-25.238 25.25c-27.444 27.458-26.625 26.526-26.692 30.373-.101 5.748 4.404 10.243 10.164 10.142 4.161-.073 3.217.748 30.772-26.779l25.004-24.98 25.152 25.154c27.401 27.403 26.415 26.538 30.312 26.606 5.731.1 10.227-4.413 10.126-10.165-.073-4.16.739-3.226-26.692-30.684L114.181 100l24.892-24.917c22.052-22.073 24.993-25.137 25.775-26.851 1.761-3.855 1.002-8.092-1.985-11.079-2.033-2.034-4.039-2.821-7.172-2.816-4.072.006-3.02-.912-30.52 26.626l-24.996 25.031-25.004-24.977C47.282 33.158 48.632 34.319 44.167 34.363c-1.284.013-3.005.26-3.825.549" fill="white" fill-rule="evenodd"/></svg>
            </span>
          </button>
          <div class="zw-caption js-caption" style="background: ${this.captionBg}">
            <span class="zw-caption-text">
              ${this.captionText}
            </span>
          </div>
          <div class="zw-image-container">
            ${this.getMediaAssetHtml()}
            <svg class="zw-large-icon" fill="none" height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M14.5 9.5L21 3M21 3H15M21 3V9M3 21L9.5 14.5M3 21V15M3 21H9" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>
          </div>
        </div>
      </div>
    `
  }

  getMediaAssetHtml () {
    if (this.fileType === 'mp4') {
      return `
        <video width="320" height="240" loop autoplay muted playsinline>
          <source src="${this.mediaAsset}" type="video/mp4">
        </video>
      `
    }

    return `<img src="${this.mediaAsset}">`
  }

  showOverlay () {
    this.hidePopover()
    const el = document.createElement('div')
    const elStory = document.createElement('div')
    const closeButton = document.createElement('a')
    const iframe = document.createElement('iframe')

    closeButton.addEventListener('click', this.hideOverlay.bind(this))

    el.id = 'zwappy-overlay'
    elStory.id = 'zwappy-overlay-story'
    closeButton.id = 'zwappy-overlay-close'
    closeButton.innerHTML = '<span class="zw-overlay-close-icon"><svg height="200" width="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"><path d="M40.342 34.912c-2.15.759-4.747 3.469-5.524 5.765-.805 2.381-.744 5.137.161 7.2.585 1.335 5.184 6.098 25.948 26.873L86.165 100l-25.238 25.25c-27.444 27.458-26.625 26.526-26.692 30.373-.101 5.748 4.404 10.243 10.164 10.142 4.161-.073 3.217.748 30.772-26.779l25.004-24.98 25.152 25.154c27.401 27.403 26.415 26.538 30.312 26.606 5.731.1 10.227-4.413 10.126-10.165-.073-4.16.739-3.226-26.692-30.684L114.181 100l24.892-24.917c22.052-22.073 24.993-25.137 25.775-26.851 1.761-3.855 1.002-8.092-1.985-11.079-2.033-2.034-4.039-2.821-7.172-2.816-4.072.006-3.02-.912-30.52 26.626l-24.996 25.031-25.004-24.977C47.282 33.158 48.632 34.319 44.167 34.363c-1.284.013-3.005.26-3.825.549" fill="white" fill-rule="evenodd"/></svg></span>'

    iframe.setAttribute('src', this.url)
    iframe.style.width = '100%'
    iframe.style.height = '100%'
    iframe.style.border = 'none'

    el.appendChild(elStory)
    elStory.appendChild(iframe)
    elStory.appendChild(closeButton)
    document.body.appendChild(el)
  }

  hideOverlay () {
    document.querySelector("#zwappy-overlay").remove()
    this.showPopover()
  }
}

new ZwappyStory